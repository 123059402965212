<template>
  <div class="page-component">
    <h3>{{ calculatorTitle }}</h3>
    <p class="description">
      Fill in the required fields and we will do the rest. You can also
      experiment with some numbers to see the value of what -could- be, which
      can be motivational.
    </p>
    <b-row>
      <b-col col-sm-12 col-md-6>
        <table>

          <tr>
            <td class="input-prompt" v-b-popover.hover.top="'This is the total number of employees, residents, and staff in the organization.  These are people that can do business with you'">Accounts / Employees #</td>
            <td>
              <b-input class="input-number" v-model.number="numEmployees" />
            </td>
            <td class="input-help">
              How many employees and other accounts are in your organization?
            </td>
          </tr>

          <tr>
            <td class="input-prompt" v-b-popover.hover.top="'Include sales for all locations'">Total Annual Sales $</td>
            <td class="input-number">
              <currency-input v-model="annualCafeteriaSales" :options="{ currency: 'USD' }" />
            </td>
            <td class="input-help">Sum all sales for all locations that participate in this program</td>
          </tr>

          <tr>
            <td class="input-prompt" v-b-popover.hover.top="'Fee-Free Cashless transactions are your most profitable way to sell'">Estimated Cashless Participation %</td>
            <td>
              <b-input class="input-number" v-model.number="participationCount" @change="participationCountChanged()" />
            </td>
            <td class="input-help">What precentage of your total population do you expect will participate in this system</td>
          </tr>

          <tr>
            <td class="input-prompt">Daily Transactions #</td>
            <td>
              <b-input class="input-number" v-model.number="numDailyTransactions" @change="dailyTransactionsChanged()" />
            </td>
            <td class="input-help">This is an average estimate of TOTAL transactions.  If you look at the # Annual Transactions figure to the right you can tweak this figure
              as needed</td>
          </tr>

          <tr>
            <td class="input-prompt">Daily Charge Transactions #</td>
            <td>
              <b-input class="input-number" v-model.number="numDailyChargeTransactions" @change="dailyChargeTransactionsChanged()" />
            </td>
            <td class="input-help">How many, of the total, are charge transactions</td>
          </tr>

          <tr>
            <td class="input-prompt">Average Hourly FTE Wage $</td>
            <td class="input-number"><currency-input v-model="averageWage" :options="{ currency: 'USD' }" /></td>
            <td class="input-help"></td>
          </tr>

          <tr>
            <td class="input-prompt">Food Cost %</td>
            <td>
              <b-input class="input-number" v-model.number="foodCostPrecent" />
            </td>
            <td class="input-help"></td>
          </tr>

          <tr>
            <td class="input-prompt">Estimated Traffic Increase %</td>
            <td>
              <b-input class="input-number" v-model.number="trafficIncreasePrecent" />
            </td>
            <td class="input-help">
              The estimated traffic increase with your cashless payment solution. This is typeically 20% if you do NOT have an existing cashless
              solution
            </td>
          </tr>

          <tr>
            <td class="input-prompt">Estimated Check Increase %</td>
            <td>
              <b-input class="input-number" v-model.number="checkIncreasePrecent" />
            </td>
            <td class="input-help">Implementing cashless typically increases spend by 10 to 20%. If you have a current cashless solution, a new
              solution may not add any per-check value</td>
          </tr>

          <tr>
            <td class="input-prompt" v-b-popover.hover.top="'If this is a one-time purchase, consider the annual maintenance for 3 years in addition to the purchase price.  For a SaaS application, consider the 3 year investment for comparison. Also, include installation and setup fees for a complete picture'" >Total System Investment $ <span style="font-size:.8em;">(3 years)</span></td>
            <td>
              <currency-input v-model="investment" :options="{ currency: 'USD' }" />
            </td>
            <td class="input-help">Total investment in the system over 3 years
            </td>
          </tr>

        </table>
      </b-col>
      <b-col col-sm-12 col-md-6>
        <h3>Results</h3>

        <b-tabs>
          <b-tab title="General Stats">
            <table style="max-width:90%;width:90%;background:lightgray;color:black;border-radius:1em;margin:1em;">

              <tr>
                <td class="input-prompt"># Annual Transactions</td>
                <td class="input-number">
                  {{
                    currency(totalAnnualTransactions, {
                      precision: 0,
                      symbol: "",
                    }).format()
                  }}
                </td>
                <td class="input-help">Based on # daily transactions for the year</td>
              </tr>

              <tr>
                <td class="input-prompt"># Charge Transactions</td>
                <td class="input-number">
                  {{ currency(totalAnnualChargeTransactions, { precision: 0, symbol: "", }).format() }}
                </td>
                <td class="input-help">Based on participation count</td>
              </tr>

              <tr>
                <td class="input-prompt">Your Average Sale $</td>
                <td class="input-number">{{ currency(avgDailySale).format() }}</td>
                <td class="input-help">based on total transactions, not just participation count</td>
              </tr>

              <tr>
                <td class="input-prompt">Your Average Daily Sale $</td>
                <td class="input-number">{{ currency(avgDailySales).format() }}</td>
                <td class="input-help">based on total transactions, not just participation count</td>
              </tr>

              <tr>
                <td class="input-prompt">Avg Increase Per Sale $</td>
                <td class="input-number">
                  {{ currency(increasedAvgCheck).format() }}
                </td>
                <td class="input-help">based on participation count per cashless sale</td>
              </tr>

              <tr>
                <td class="input-prompt">Avg Daily Increase Sales $</td>
                <td class="input-number">
                  {{ currency(avgDailyIncreaseSales).format() }}
                </td>
                <td class="input-help">Based on increase in traffic of the participating patrons</td>
              </tr>

              <tr>
                <td class="input-prompt">Avg MONTHLY Increase Sales $</td>
                <td class="input-number">
                  {{ currency(avgMonthlyIncreaseSales).format() }}
                </td>
                <td class="input-help">Based on increase in traffic overall of participating patrons</td>
              </tr>

              <tr>
                <td class="input-prompt">Avg ANNUAL Increase Sales $</td>
                <td class="input-number">
                  {{ currency(avgAnnualIncreaseSales).format() }}
                </td>
                <td class="input-help">Based on increase in traffic of participating patrons</td>
              </tr>

              <!-- <tr>
                <td class="input-prompt">Your Average Sale $</td>
                <td class="input-number">{{ currency(avgDailySale) }}</td>
                <td class="input-help"></td>
              </tr>

              <tr>
                <td class="input-prompt">Your Average Sale $</td>
                <td class="input-number">{{ currency(avgDailySale) }}</td>
                <td class="input-help"></td>
              </tr> -->

            </table>
          </b-tab>
          <b-tab title="ROI Analysis">
            <p style="margin-top:1em;margin-left:2em;margin-right:2em;color:gray;text-align:left;">ROI Analysis is based on the INCREASE in sales</p>
            <table style="max-width:90%;width:90%;background:lightgray;color:black;border-radius:1em;margin:1em;">

              <tr>
                <td class="input-prompt">Estimated Increased Sales</td>
                <td class="input-number">
                  {{ currency(avgAnnualIncreaseSales).format() }}
                </td>
                <td class="input-help">Based on # daily transactions for the year</td>
              </tr>

              <tr>
                <td class="input-prompt">Adjusted ANNUAL Increased Sales</td>
                <td class="input-number">
                  {{ currency(avgNetAnnualIncreaseSales).format() }}
                </td>
                <td class="input-help">Adjusted for Food Cost</td>
              </tr>
              <tr>
                <td class="input-prompt">Adjusted MONTHLY Increased Sales</td>
                <td class="input-number">
                  {{ currency(avgNetAnnualIncreaseSales).divide(12).format() }}
                </td>
                <td class="input-help">1 Month, adjusted for food cost</td>
              </tr>
              <tr>
                <td class="input-prompt">Investment $</td>
                <td class="input-number">
                  {{ currency(investment).format() }}
                </td>
                <td class="input-help">The amount you are investing; if monthly invenstment, # months idealy will be < 1</td>
              </tr>

              <tr>
                <td class="input-prompt"># Months Payback</td>
                <td class="input-number">
                  {{ currency(investment, { precision: 1, symbol: "", }).divide(avgNetAnnualIncreaseSales / 12).format() }}
                </td>
                <td class="input-help">If one time purchase, # months till paid; if SaaS, this is your annual investment</td>
              </tr>

            </table>
          </b-tab>

          <b-tab title="Sales Performance">
            <div style="text-align:left;height:60vh;overflow-y:auto;">
              <h4 style="margin-top:.5em;">Bottom Line</h4>
              <p>When analyzing factors, consider the following:</p>
              <ul>
                <li>Your overhead costs are mostly fixed</li>
                <li>Your labor costs vary based on staffing</li>
                <li>Your product mix can affect profitability</li>
                <li v-if="avgDailySale<4.00">Your average sale value is on the low side</li>
                <li v-if="avgDailySale>4.00 && avgDailySale<7">Your average sale value is reasonable, but could be better</li>
                <li v-if="avgDailySale>7">Your average sale value is good</li>
                <li v-if="foodCostPercentage<28">Your food cost of {{ foodCostPrecent }}% seems too low; This may indicate a quality issue</li>
                <li v-if="foodCostPercentage>28 && foodCostPrecent<36">Food costs always factor, but {{ foodCostPrecent }}% is reasonable</li>
                <li v-if="foodCostPrecent>36">Food costs of {{ foodCostPrecent }}% are on the high side; consider price increases to bring this into line</li>
                <li>Pricing can affect sales volumes; it's is a fine line here</li>
                <li>Technology can help, but does not substitute good practices</li>
              </ul>

              <p v-if="participationCount<50">One of the best options to move the needle towards more profitability is to increase your CASHLESS volume.  
                Cashless transaction are the most profitable and easiest to track and cashless encourages more generours spending.
                The # of patrons visiting your location is a key factor and easily measured.  Of course, if you are
                inefficient in your operations, you can increase your volume and still lose money.  So, be careful.
              </p>

              <p v-if="participationCount>=50 && participationCount <80">
                Your participation percentage seems to be good.  You have established trust from your patrons and they
                visit your location regularly.  Another factor now is to increase the average ticket price.  Added revenue
                per ticket can translate the quickest to your bottom line.  Showcase your profitable items and promote them.
              </p>

              <p v-if="participationCount>80">
                You are rocking the participation.  Keep up the good work.  Now, focus on increasing the average ticket price.
                Also, keep a close eye on this value and make sure it is not decreasing.  Decreasing values here may indicate
                a problem, which should be quicly addressed.  Move quickly, if this is dropping, as this metric could take longer
                to recover if quality or service is at the heart.
              </p>

              <p>Product mix, highlighting your profitable items to increase their sales is another valuable tip.
                If you have a product that is popular, but not profitable, consider increasing the price or removing it
                from your menu.  If you have a product that is profitable, but not popular, consider promoting it more.
                I would highly caution against lessening the quality of the product to meet a target price.  That is a
                race to the bottom in several areas.
              </p>
            </div>
          </b-tab>

          <b-tab title="Investment Analysis">
            
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col col-sm-12 col-md-6 style="padding:2em;">
        <h4 style="text-align:left;">Observations</h4>
        <p class="input-help">Several factors seem pretty obvious. Increase participation and your sales increase by default and that makes an impact
          pretty quickly</p>
        <p class="input-help">Increase the overall traffic, which happens when you make it easy to buy from you AND you have product patrons WANT to
          buy</p>
        <p class="input-help">Increasing your average check value has a more direct impact on your bottom line</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import CurrencyInput from "./CurrencyInput";
import Currency from "currency.js";

export default {
  name: "HealthcareROI",
  props: {
    calculatorTitle: { type: String, default: "Calculator Title Here" },
    calculatorDescription: { type: String, default: "Your description here" },
  },
  components: {
    CurrencyInput,
  },
  computed: {
    numAnnualTransactions() {
      return this.numDailyTransactions * 365;
    },
    numAnnualChargeTransactions() {
      return this.numDailyChargeTransactions * 365;
    },
    avgDailySale() {
      if (this.numDailyTransactions == 0) {
        return "";
      }
      return this.annualCafeteriaSales / this.totalAnnualTransactions;
    },
    avgDailySales() {
      if (this.numDailyTransactions == 0) {
        return "";
      }
      return this.avgDailySale * this.numDailyTransactions;
    },
    avgDailyIncreaseSales() {
      let increase = (this.avgDailySales * (this.participationCount / 100)) * (this.trafficIncreasePrecent / 100);

      increase += this.increasedAvgCheck * this.numDailyTransactions * (1 + (this.trafficIncreasePrecent / 100)) * (this.participationCount / 100);
      return increase
    },
    avgMonthlyIncreaseSales() {
      return this.avgAnnualIncreaseSales / 12;
    },
    avgAnnualIncreaseSales() {
      return this.avgDailyIncreaseSales * 365;
    },
    avgNetAnnualIncreaseSales() {
      let si = this.avgAnnualIncreaseSales;
      let cogs = si * (this.foodCostPrecent / 100);
      return Currency(si).subtract(cogs).value;
    },
    totalAnnualTransactions() {
      return this.numDailyTransactions * 365;
    },
    totalAnnualChargeTransactions() {
      return this.numDailyChargeTransactions * 365;
    },
    dailyIncreasedSales() {
      return (
        this.increasedAvgCheck * (this.numDailyTransactions * (this.trafficIncreasePrecent / 100))
      );
    },
    increasedSalesRevenue() {
      return (
        this.increasedAvgCheck *
        (this.numDailyTransactions * (this.trafficIncreasePrecent / 100)) *
        365
      );
    },
    increasedAvgCheck() {
      return this.avgDailySale * (this.checkIncreasePrecent / 100);
    },
    increasedAvgCheckRevenue() {
      return this.increasedAvgCheck * this.numberChargeTransactions;
    },
  },
  data() {
    return {
      name: "abc",
      investment: 100000,
      numEmployees: 500,
      numResidents: 500,

      annualCafeteriaSales: 550000,

      numDailyTransactions: 200,
      numDailyChargeTransactions: 100,

      foodCostPrecent: 30,
      averageWage: 15,
      participationCount: 50,

      checkIncreasePrecent: 10,
      trafficIncreasePrecent: 20,
    };
  },
  methods: {
    currency(x, y) {
      return Currency(x, y);
    },
    dailyTransactionsChanged() {
      console.log( "chage 1 change");
      if( !this.numDailyChargeTransactions  ) {
        this.numDailyChargeTransactions = Currency(this.numDailyTransactions).divide( this.participationCount ).value;
      }
      this.participationCountChanged();
      this.dailyChargeTransactionsChanged();
    },
    dailyChargeTransactionsChanged() {
      console.log( "charge 2 changed" );
      if( this.numDailyTransactions) {
        this.participationCount = Currency( this.numDailyChargeTransactions ).divide(this.numEmployees).multiply(100).value;
      }
    },
    participationCountChanged() {
      console.log( "participation changed" );
      if( this.numDailyTransactions ) {
        this.numDailyChargeTransactions = Currency(this.numDailyTransactions).multiply( this.participationCount ).divide(100).value;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  // border-bottom:1px solid gray;
  padding: 0.5em;
}
</style>
