<template>
  <div class="container page-global">
    <b-row>
      <b-col col cols="12" sm="12" md="12" lg="6">
          <img
            class="home"
            alt="Cashless FYI"
            style="width:256px;padding-bottom:2em;"
            src="../assets/cashlessfyi-logo-512.png"
          />
          <b-input-group style="padding-bottom:2em;">
            <label style="padding-right:1em;margin-top:.5em;"
              >Calculator Selection</label
            >
            <b-select
              style="max-width:25em;border-radius:.5em;"
              :options="calculatorOptions"
              v-model="calculator"
            />
          </b-input-group>
      </b-col>

      <b-col col cols="12" sm="12" md="12" lg="6">
        <div style="padding-left:5em;padding-right:0;color:orange;text-align:left;" >
          NOTICE: We do not make any warranties or claims about the usefulness
          or accuracy of these calculators. We obviously try to make them as
          accurate as possbile but this IS a side-project. If you would a specific
          calculator online, please drop us a note with some details.
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col col-12>
        <div v-if="calculator == 'hospital'">
          <healthcare-roi-calculator :calculatorTitle="calcTitle()" />
        </div>
      </b-col>
    </b-row>
    <!-- <hr/>
    <p style="font-size:.7em;">(c) 2020-{{1900 + (new Date().getYear())}}, <a href="https://www.ioihan.com" target="_blank">IOIHAN Development</a>, All Rights Reserved :: Version {{$root.appVersion}} (beta)</p> -->
  </div>
</template>
<script>
import HealthcareRoiCalculator from "../components/HealthcareROICalculator.vue";

export default {
  name: "Calculators",
  components: {
    HealthcareRoiCalculator,
  },
  data() {
    return {
      calculatorOptions: [
        { text: "Payroll Deduction ROI (healthcare)", value: "hospital" },
        // {text: 'Senior Care Cashless & Meal Plans ROI', value: 'senior' },
      ],
      calculator: "hospital",
    };
  },
  methods: {
    calcTitle() {
      let c = this.calculatorOptions.filter((c) => {
        return c.value === this.calculator;
      });
      if (!c || c.length != 1) {
        return "Calculator";
      }
      return c[0].text;
    },
  },
};
</script>
<style></style>
