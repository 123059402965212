<template>
  <input 
    ref="inputRef"
    type="text"
    :value="formattedValue"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
// import Currency from "currency.js";

export default {
  name: 'CurrencyInput',
  props: {
    value  : Number,   // Vue 2: value
    options: Object
  },
  setup (props) {
    const { formattedValue, inputRef } = useCurrencyInput(props.options)

    return { inputRef, formattedValue }
  }
}
</script>
<style lang="scss" scoped>

  input {
    padding:.5em;
    width:8em;
    border-radius:.5em;
    font-weight:500;
  }

</style>